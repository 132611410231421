import React, { useState } from 'react';
import Chat from './components/chat/js/chat';
import './App.css';
import cross from './assets/cross.svg';
import logo from './assets/logo_2.svg';
import logoIngos from './assets/logo-ingos.svg';

const App = () => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [ChoosenClinic, setChoosenClinic] = useState('');

  const handleClinicSelection = (clinic) => {
    setChoosenClinic(clinic);
    setIsModalOpen(false);
  };

  return (
    <div className='container app-container'>
      <header className='header'>
        <div className='d-flex flex-row align-items-center justify-content-center'>
          <img className='header-logo ld' alt='LazyDoc' src={logo} />
          <img alt='cross' src={cross} />
          <img className='header-logo ingos' alt='Ингосстрах' src={logoIngos} />
        </div>
      </header>

      {isModalOpen && (
        <div className='modal-x'>
          <div className='modal-x-content'>
            <h2>Выберите тип обращения</h2>
            <button className='btn btn-primary' onClick={() => handleClinicSelection('beHealthyAppointment')}>
              Будь Здоров - Приём
            </button>
            <button className='btn btn-primary' onClick={() => handleClinicSelection('beHealthyDischargeEpicrisis')}>
              Будь Здоров - Выписной эпикриз
            </button>
            <button className='btn btn-primary' onClick={() => handleClinicSelection('virtualClinic')}>
              Виртуальная клиника
            </button>
            <button className='btn btn-primary' onClick={() => handleClinicSelection('mkc')}>
              МКЦ
            </button>
          </div>
        </div>
      )}

      <main className='chat-wrapper'>
        <Chat selectedClinic={ChoosenClinic} />
      </main>
    </div>
  );
};

export default App;
