import 'react-chat-elements/dist/main.css';
import '../styles/chat.css';

import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { addMessage } from './chatSlice';
import { Input, MessageBox } from 'react-chat-elements';
import MoonLoader from "react-spinners/MoonLoader";


import arrow from '../../../assets/send.svg';
import attach from '../../../assets/attach.svg';

const Chat = ({ selectedClinic }) => {
    const messages = useSelector((state) => state.chat.messages);
    const dispatch = useDispatch();
    const inputRef = useRef(null);
    const messagesEndRef = useRef(null);

    const [sessionNumber, setSessionNumber] = useState(null)
    const [inputValue, setInputValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const audioRef = useRef()
    const handleAudioRefClick = (e) => {
        audioRef.current.click()
    }

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);

    async function sendMessage(text) {
        if (inputValue === '' && text === null) return;
        var userText = inputValue === '' ? text : inputValue

        setIsLoading(true);

        const userMessage = {
            sender: 'user',
            text: userText,
            type: 'text',
            date: new Date(),
        };
        dispatch(addMessage(userMessage));
        var sessionId = null
        var inputText = userText

        setInputValue('');

        if (inputRef.current) {
            inputRef.current.value = '';
        }

        if (sessionNumber === null) {
            try {
                const response = await axios.post('https://gptdb.telemedhub.io/api/newSession/', {
                    "api_key": "78e6a52c-97b3-4363-82df-145d4a552b6e",
                    "model": selectedClinic
                });
                setSessionNumber(response.data.session_id)
                sessionId = response.data.session_id
            } catch (error) {
                console.error('Error sending message', error);
            }
        }
        try {
            const sendMessageResponse = await axios.post('https://gptdb.telemedhub.io/api/sendMessage/', { "api_key": "78e6a52c-97b3-4363-82df-145d4a552b6e", "session_id": sessionNumber === null ? sessionId : sessionNumber, "message": inputText });

            const botMessage = {
                sender: 'bot',
                text: sendMessageResponse.data.response,
                type: 'text',
                date: new Date(),
            };

            dispatch(addMessage(botMessage));
        } catch (error) {
            console.error('Error sending message', error);
        }

        setIsLoading(false);
    };

    const sendAudioMessage = async (audioFile) => {
        setIsLoading(true);

        const audioMessage = {
            sender: 'user',
            type: 'audio',
            date: new Date(),
            data: {
                audioURL: URL.createObjectURL(audioFile),
                audioType: audioFile.type,
                controlsList: 'nodownload',
            },
        };

        const formData = new FormData();
        formData.append('audio_file', audioFile);

        dispatch(addMessage(audioMessage));

        try {
            var transcribe = await axios.post('https://gptdb.telemedhub.io/api/transcribe/', formData);
            const data = transcribe.data;
            await sendMessage(data['transcript']);
        } catch (error) {
            console.error('Error uploading file:', error);
        }

        setIsLoading(false);
    };

    const handleAudioUpload = (event) => {
        const audioFile = event.target.files[0];
        if (audioFile) {
            sendAudioMessage(audioFile);
        }
    };

    return (
        <div className="chat-container">
            <div className="messages-container">
                {messages.map((msg, index) => (
                    <MessageBox
                        className='message-box-user'
                        key={index}
                        position={msg.sender === 'user' ? 'right' : 'left'}
                        type={msg.type}
                        text={msg.text}
                        data={msg.type === 'audio' ? msg.data : {}}
                        date={msg.date}
                        notch={true}
                        status={msg.sender === 'user' ? 'read' : 'received'}
                    />
                ))}
                <div ref={messagesEndRef} />
            </div>

            <Input
                className='ai-input-field'
                referance={inputRef}
                placeholder='Введите сообщение...'
                multiline={true}
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                leftButtons={
                    <>
                        <input
                            ref={audioRef}
                            type="file"
                            accept="audio/*"
                            style={{ display: 'none' }}
                            id="audio-upload"
                            onChange={handleAudioUpload}
                        />
                        <label htmlFor="audio-upload">
                            <button className="round-button" onClick={handleAudioRefClick} disabled={isLoading}>
                                {isLoading ? (
                                    <MoonLoader
                                        loading={isLoading}
                                        color='white'
                                        size={24}
                                        aria-label="Загрузка"
                                        data-testid="loader"
                                    />
                                ) : (
                                    <img alt='Прикрепить аудиозапись' src={attach} />
                                )}
                            </button>
                        </label>
                    </>
                }
                rightButtons={
                    <button className="round-button" onClick={() => sendMessage(null)} disabled={isLoading}>
                        {isLoading ? (
                            <MoonLoader
                                loading={isLoading}
                                color='white'
                                size={24}
                                aria-label="Загрузка"
                                data-testid="loader"
                            />
                        ) : (
                            <img alt='Отправить' src={arrow} />
                        )}
                    </button>
                } />

            {/* <p className="error-message">Искусственный интеллект может допускать ошибки. Рекомендуем проверять важную информацию.</p> */}
        </div>
    );
};

export default Chat;
